import req from "../methods/req"
import method from '../methods/method'

export const getContractCompanyList = function (data){
    return method.get_list({
        url:'/manage/company.index/getContractCompanyList',
        ...data
    })
}

export const getContractCompanyDetail = function (data){
    return req({
        url:'/manage/company.index/getContractCompanyDetail',
        ...data
    })
}



//组织列表
export const getFramework = (data) => req({url: '/manage/organize.framework/getFramework',...data})

//新增编辑
export const modifyFramework = (data) => req({url: '/manage/organize.framework/modifyFramework',...data})
//设置负责人
export const setLeader = (data) => req({url: '/manage/organize.framework/setLeader',...data})

//启用禁用删除
export const changeStatus = (data) => req({url: '/manage/organize.framework/changeStatus',...data})

//排序
export const sortFramework = (data) => req({url: '/manage/organize.framework/sortFramework',...data})

//历史组织
export const historyFramework = (data) => req({url: '/manage/organize.framework/historyFramework',...data})

//操作日记
export const operateRecord = (data) => req({url: '/manage/organize.framework/operateRecord',...data})

//合并
export const mergeFramework = (data) => req({url: '/manage/organize.framework/mergeFramework',...data})

//组织列表
export const getFrameworkUser = (data) => req({url: '/manage/organize.framework/getFrameworkUser',...data})

//组织添加成员
export const addUser = (data) => req({url: '/manage/organize.framework/addUser',...data})

//批量调整
export const batchModify = (data) => req({url: '/manage/organize.framework/batchModify',...data})

// 标签
//合并
export const mergeLabel = (data) => req({url: '/manage/organize.label/mergeLabel',...data})

//列表
export const labelList2 = (data) => req({url: '/manage/organize.label/labelList',...data})
export const labelList = function (data){
    return method.get_list({
        url:'/manage/organize.label/labelList',
        ...data
    })
}


//添加
export const modifyLabel = (data) => req({url: '/manage/organize.label/modifyLabel',...data})

//删除
export const removeLabel = (data) => req({url: '/manage/organize.label/removeLabel',...data})
//排序
export const labelSort = (data) => req({url: '/manage/organize.label/labelSort',...data})

// 工作地点


//列表 
export const workLocationCategory = (data) => req({url: '/manage/organize.work_location/workLocationCategory',...data})

export const workLocationCategory2 = function (data){
    return method.get_list({
        url:'/manage/organize.work_location/workLocationCategory',
        ...data
    })
}
//添加
export const modifyWorkLocationCategory = (data) => req({url: '/manage/organize.work_location/modifyWorkLocationCategory',...data})

//删除
export const removeWorkLocationCategory = (data) => req({url: '/manage/organize.work_location/removeWorkLocationCategory',...data})
//排序
export const workLocationSort = (data) => req({url: '/manage/organize.work_location/workLocationSort',...data})

//地点列表
export const workLocation2 = (data) => req({url: '/manage/organize.work_location/workLocation',...data})
export const workLocation = function (data){
    return method.get_list({
        url:'/manage/organize.work_location/workLocation',
        ...data
    })
}
//新增工作地点列表
export const modifyWorkLocation = (data) => req({url: '/manage/organize.work_location/modifyWorkLocation',...data})
//合并
export const mergeWorkLocation = (data) => req({url: '/manage/organize.work_location/mergeWorkLocation',...data})
// 删除地址
export const removeWorkLocation = (data) => req({url: '/manage/organize.work_location/removeWorkLocation',...data})


//获取工作区域工作列表树形数据
export const getWorkLocationTree = (data) => req({url: '/manage/organize.work_location/getWorkLocationTree',...data})
// 职务

//列表
export const dutiesList2 = (data) => req({url: '/manage/organize.duties/dutiesList',...data})
export const dutiesList = function (data){
    return method.get_list({
        url:'/manage/organize.duties/dutiesList',
        ...data
    })
}


// //编辑新增
export const modifyDuties = (data) => req({url: '/manage/organize.duties/modifyDuties',...data})
// // 删除
export const removeDuties = (data) => req({url: '/manage/organize.duties/removeDuties',...data})
// 合并
export const mergeDuties = (data) => req({url: '/manage/organize.duties/mergeDuties',...data})
//排序/
export const dutiesSort = (data) => req({url: '/manage/organize.duties/dutiesSort',...data})
// 详情
export const dutiesInfo = (data) => req({url: '/manage/organize.duties/dutiesInfo',...data})


// 职级
//通道列表
export const rankChannel = (data) => req({url: '/manage/organize.rank/rankChannel',...data})
//通道 排序
export const rankChannelSort = (data) => req({url: '/manage/organize.rank/rankChannelSort',...data})
 // 新增编辑职级通道
export const modifyRankChannel = (data) => req({url: '/manage/organize.rank/modifyRankChannel',...data})
// 删除通道
export const removeRankChannel = (data) => req({url: '/manage/organize.rank/removeRankChannel',...data})


// 职级 
export const rankList = (data) => req({url: '/manage/organize.rank/rankList',...data})
// // 详情
export const rankChannelGroup = (data) => req({url: '/manage/organize.rank/rankChannelGroup',...data})
//f分组
export const modifyRankGroup = (data) => req({url: '/manage/organize.rank/modifyRankGroup',...data})
 // 新增编辑职级
export const modifyRank = (data) => req({url: '/manage/organize.rank/modifyRank',...data})
// 删除通道
export const removeRank = (data) => req({url: '/manage/organize.rank/removeRank',...data})

// 批量修改
export const batchAddRank = (data) => req({url: '/manage/organize.rank/batchAddRank',...data})



// 岗位分类 
export const roleCate = (data) => req({url: '/manage/organize.post/roleCate',...data})

export const modifyRoleCate = (data) => req({url: '/manage/organize.post/modifyRoleCate',...data})

export const removeRoleCate = (data) => req({url: '/manage/organize.post/removeRoleCate',...data})


// 岗位

export const roleList = (data) => req({url: '/manage/organize.post/roleList',...data})
export const setHeadcount = (data) => req({url: '/manage/organize.post/setHeadcount',...data})
export const setRank = (data) => req({url: '/manage/organize.post/setRank',...data})
export const modifyRole = (data) => req({url: '/manage/organize.post/modifyRole',...data})


export const rankTree = (data) => req({url: '/manage/organize.rank/rankTree',...data})


export const changePostStatus = (data) => req({url: '/manage/organize.post/changeStatus',...data})


export const setFrameworkHeadcount = (data) => req({url: '/manage/organize.framework/setHeadcount',...data})

export const roleInfo = (data) => req({url: '/manage/organize.post/roleInfo',...data})
export const mergePost = (data) => req({url: '/manage/organize.post/mergePost',...data})

export const setReportRelation = (data) => req({url: '/manage/organize.post/setReportRelation',...data})

export const setCustomAttr = (data) => req({url: '/manage/organize.post/setCustomAttr',...data})


export const setRoleAttr = (data) => req({url: '/manage/organize.post/setRoleAttr',...data})


export const getRoleTree = (data) => req({url: '/manage/organize.post/getRoleTree',...data})


export const roleSort = (data) => req({url: '/manage/organize.post/roleSort',...data})

export const customChangeStatus = (data) => req({url: '/manage/organize.post/customChangeStatus',...data})



export const batchAddRoleCate = (data) => req({url: '/manage/organize.post/batchAddRoleCate',...data})








export const batchUpdateFramework = (data) => req({url: '/manage/organize.import/batchUpdateFramework',...data})

export const batchAddFramework = (data) => req({url: '/manage/organize.import/batchAddFramework',...data})


export const exportUpdateFramework = (data) => req({url: '/manage/organize.export/exportUpdateFramework',...data})


export const exportRank = (data) => req({url: '/manage/organize.export/exportRank',...data})


export const exportFramework = (data) => req({url: '/manage/organize.export/exportFramework',...data})

export const updateFramework = (data) => req({url: '/error/error_download/updateFramework',...data})

export const framework = (data) => req({url: '/error/error_download/framework',...data})

export const batchAddLocation = (data) => req({url: '/manage/organize.import/batchAddLocation',...data})

export const batchAddRole = (data) => req({url: '/manage/organize.import/batchAddRole',...data})

export const getRank = (data) => req({url: '/manage/crm.user/getRank',...data})



