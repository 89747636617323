<template>
	<div class='enterprise'>
		<!-- <div class="cq_back_text">
			<span @click='$router.back()'>返回</span><a-divider type="vertical" /><span>职务管理</span><a-divider
				type="vertical" /><span class="back_text">调整排序</span>
		</div> -->
		<div class="breadcrumbTop">
			<div class="FieldSettingsBox">
				<div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
					<span>
						&nbsp; &nbsp;
					</span> <span>职务名称</span> <span></span> <span style="color: #666;" class="w100">操作</span>
				</div>
				<!-- <draggable v-model="all_list" chosenClass="chosen" forceFallback="true" group="people" animation="1000"
					@start="onStart" @end="onEnd">
					<transition-group> -->
				<div :draggable="true" @dragstart="dragstart($event, -1, index)" @dragover="dragover"
					@dragenter="dragenter($event, -1, index)" @dragend="dragend($event, -1, index)" @drop="drop"
					class="FieldSettingsBoxTitle" v-for="(element,index) in all_list" :key="element.id">
					<span>
						<a-icon type="unordered-list" />
					</span>
					<span>
						{{element.name}}
					</span>
					<span> </span>

				</div>
				<!-- </transition-group>
				</draggable> -->
			</div>
		</div>
		<a-drawer placement="bottom" :closable="false" height="auto" :mask='false' :visible="visible"
			@close="$router.back()">
			<div style="text-align: center;">
				<a-button class='pointerCancel' @click='$router.back()'>取消</a-button>
				<a-button class='pointerCancel' style="margin-left: 20px;" type='primary'
					@click="sort_ok">确认排序</a-button>
			</div>
		</a-drawer>
	</div>
</template>

<script>
	import {
		dutiesSort,
		dutiesList2
	} from '@/api/organization.js'


	// import {
	// 	VueDraggableNext
	// } from 'vue-draggable-next'
	export default {
		components: {

			// draggable: VueDraggableNext,
		},
		data() {
			return {
				visible: true,
				all_list: [],
				stop_approval_index: null,
			}

		},
		created() {
			this.get_list()
		},
		methods: {
			dragstart(e, ind) {
				// e.preventDefault()
			},
			drop(e) {
				e.preventDefault()
			},
			// 拖拽位置
			dragenter(e, ind, index) {
				e.preventDefault()
				if (ind != -1) {
					this.stop_index = ind;
				}
				this.stop_approval_index = index
			},
			dragover(e) {
				e.preventDefault()
			},
			// 拖拽完成
			dragend(e, ind, index) {
				let list = this.all_list;
				let move_data = list[index];
				list.splice(index, 1)
				list.splice(this.stop_approval_index, 0, move_data)
				this.all_list = list;
				this.onEnd()

			},
			get_list() {
				dutiesList2({
					data: {
						is_page: -1
					}
				}).then(res => {
					this.all_list = res.data.list
				})
			},
			onStart() {
				this.drag = true;
			},
			onEnd() {
				this.drag = false;
			},
			sort_ok() {
				let sort = []
				this.all_list.forEach(item => {
					sort.push({
						id: item.id
					})
				})
				dutiesSort({
					data: {
						data: sort
					},
					toast: true
				}).then(res => {

					this.$router.back()
				})
			},

		},

	}
</script>

<style lang="less" scoped>
	@import url("../../../assets/less/app.less");
	@import '../info.less';

	.enterprise {
		box-sizing: border-box;
		padding: 12px;
		padding-bottom: 30px;
		margin: 16px;
		cursor: pointer;
		background: #fff;

		.card {
			box-sizing: border-box;
			padding: 24px;
			background-color: #fff;

			.btn_group {
				display: flex;

				.search {
					width: 213px;
					margin-right: 12px;
				}
			}

			.table {
				margin-top: 28px;
			}
		}

		.ant-btn {

			&:hover,
			&:focus {
				color: @cq_color;
				border-color: @cq_color;
			}
		}

		.ant-btn-primary {
			background-color: @cq_color;
			border-color: @cq_color;
			padding: 0px 15px;
			border-radius: 4px;

			&:hover,
			&:focus {
				filter: brightness(110%);
				color: #fff;
			}
		}

		.reset {
			margin-left: 12px;
			margin-right: 40px;
		}
	}

	.card_cont {
		padding-left: 16px;
	}

	.info_top {
		margin: 0 auto;
		display: flex;
		margin-top: 24px;
		align-items: center;
		width: 100%;
	}

	img {
		width: 140px;
		height: 98px;
		opacity: 1;
		border-radius: 8px;
	}

	.simple_name {
		margin-left: 32px;
		width: 100%;
	}

	.dis_input {
		background: none;
		border: none;
		border-radius: 6px;
	}

	.dis_input {
		color: #333;
		background: none;
	}

	.industryType {
		// background: #f4f5f9;
		border: none;
		text-align: left;
	}

	/deep/.ant-form-item {
		margin-bottom: 5px;
		color: #999999;
	}

	// .form_dom {

	/deep/.ant-form-item {
		margin-bottom: 5px;
		color: #999999;
	}

	/deep/.ant-upload-list-picture-card .ant-upload-list-item {
		margin: 0;
		width: 140px;
		height: 98px;
	}

	/deep/.ant-upload-list-picture-card-container {
		margin: 0;
		width: 140px;
		height: 98px;
	}

	.ant-row {
		margin-bottom: 0px;
	}

	// }

	.top_info {
		display: flex;
		line-height: 44px;
		align-items: center;
		border-bottom: 1px solid #e2e2e2;
		margin-top: 30px;

		.title_name {
			flex: 1;
			font-size: 16px;
			font-weight: bold;
		}
	}

	.logo_info {
		margin-top: 12px;
		width: 140px;
		height: 98px;
	}

	.card {
		width: 100%;
	}

	.qn_error_color {
		border-color: @cq_error;
	}

	.upload_box {
		display: flex;
		align-items: center;
		margin-top: 12px;
		margin-bottom: 20px;
	}

	.simple_box {
		display: flex;
		width: 100%;
	}

	.simple_big_name {
		flex: 2;
		color: @cq_text_c8;
		font-size: 30px;
		margin-left: 8px;

	}

	.agreement {
		height: 44px;
		display: flex;
		margin-top: 10px;
		border-radius: 4px;
		padding-left: 8px;
		align-items: center;
		border: 1px solid @cq_line_e;

		.agreement_name {
			flex: 1;
		}

		.agreement_op {
			span {
				margin-right: 8px;
				color: @cq_icon_color;
				cursor: pointer;
			}

			span:nth-child(3) {
				color: @cq_text_c9;
			}
		}
	}

	.agreementMoer {
		height: 44px;
		line-height: 44px;
		text-align: center;
		margin-top: 10px;
		border-radius: 4px;
		padding-left: 8px;
		align-items: center;
		border: 1px solid @cq_line_e;
	}

	.agreement:hover,
	.agreementMoer:hover {
		box-shadow: 0 3px 6px rgba(28, 36, 56, .1)
	}

	.breadcrumbTop {
		margin: 16px;

	}

	.tips_top_box {
		margin-top: 24px;
	}



	.FieldSettingsBox {
		border: 1px solid @cq_line_e;
		margin-top: 12px;
	}

	.FieldSettingsBoxHeader {
		background: #f5f8fa;
	}

	.FieldSettingsBoxTitle:hover {
		background: #f5f8fa;

		span:nth-child(4) {
			color: #666;
		}

	}

	.mar_30 {
		margin-top: 30px;
	}

	.info {
		font-size: 14px;
		color: #333;
	}


	.breadcrumbTop {
		flex: 1;
	}

	.table_list_body {
		margin: 0;
		padding: 0;
	}

	.check {
		color: @cq_color;
		cursor: pointer;
	}

	.del {
		color: @cq_error;
	}

	.FieldSettingsBox {
		// border: 1px solid @cq_line_e;
		margin-top: 12px;
		width: 100%;
	}

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;

		span:nth-child(2) {
			flex: 4;
			margin-left: 8px;
		}

		span:nth-child(3) {
			flex: 1;
		}

		span:nth-child(4) {
			color: #fff;

		}
	}

	.FieldSettingsBoxHeader {
		background: #f5f8fa;
	}

	.FieldSettingsBoxTitle:hover {
		background: #f5f8fa;

		span:nth-child(4) {
			color: #666;
		}

	}

	.FieldSettingsBoxActive {
		border-right: 3px solid @cq_color;
		background: #f8f8f9;
	}

	.add_info {
		margin-top: 12px;
		display: flex;
		border: 1px solid #eee;
		padding: 12px;
	}

	.show_type {
		border-left: 2px solid #0079FF;
		padding-left: 12px;
	}

	.w100 {
		width: 100px;
		text-align: left;
	}
</style>